import { SnackbarProvider, SnackbarProviderProps } from "notistack";
import { useTheme, Breakpoint } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

export default function MobileAwareSnackbarProvider({
  denseBelow,
  ...props
}: SnackbarProviderProps & {
  denseBelow: Breakpoint | number;
}) {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down(denseBelow));

  return <SnackbarProvider dense={matches} {...props} />;
}
