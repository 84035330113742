import { Fragment } from "react";
import { Book, NavItem } from "epubjs";
import { List, ListItem, ListItemText, ListItemIcon } from "@mui/material";
import LinkOffIcon from "@mui/icons-material/LinkOff";

export default function TableOfContentsList({
  book,
  onClick,
}: {
  book: Book;
  onClick: (item: NavItem) => void;
}) {
  const toc = book.navigation.toc;

  const handleClick = (tocItem: NavItem) => () => {
    onClick(tocItem);
  };
  return (
    <List>
      {toc.map((tocItem) => (
        <Fragment key={tocItem.id}>
          {book.spine.get(tocItem.href) ? (
            <ListItem button onClick={handleClick(tocItem)}>
              <ListItemText>{tocItem.label}</ListItemText>
            </ListItem>
          ) : (
            <ListItem>
              <ListItemIcon>
                <LinkOffIcon fontSize="small" color="error" />
              </ListItemIcon>
              <ListItemText>{tocItem.label}</ListItemText>
            </ListItem>
          )}
        </Fragment>
      ))}
    </List>
  );
}
