import React from "react";
import { createRoot } from "react-dom/client";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import App from "./components/App";
import theme from "./theme";
import localForage from "localforage";
import SpeechSynth from "./services/SpeechSynth";
import MobileAwareSnackbarProvider from "./components/providers/MobileAwareSnackbarProvider";
import { SettingsProvider } from "./components/providers/settings";
import { BookCollectionProvider } from "./components/providers/bookCollection";
import registerServiceWorker from "./registerServiceWorker";
import "./style.css";

const synth = new SpeechSynth();
const storage = localForage.createInstance({
  name: "books",
  version: 1.0,
});
const defaultSettings = {
  font: {
    size: "100%",
    lineHeight: "2em",
  },
};

const root = createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <MobileAwareSnackbarProvider
        denseBelow="sm"
        hideIconVariant
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <SettingsProvider storage={storage} defaultSettings={defaultSettings}>
          <BookCollectionProvider storage={storage}>
            <App speechSynth={synth} />
          </BookCollectionProvider>
        </SettingsProvider>
      </MobileAwareSnackbarProvider>
    </ThemeProvider>
  </React.StrictMode>
);

registerServiceWorker({ updateInterval: 1000 * 60 * 60 });
