import { HTMLAttributes, useEffect, useRef, useState } from "react";
import { selectTextInDocument } from "../../../services/selectTextInDocument";
import useDebounce from "../../providers/useDebounce";
import Book from "epubjs/types/book";
import { BookLocation } from "../../../services/BookRepository";
import Rendition, { Location } from "epubjs/types/rendition";
import ArrowBackIosRoundedIcon from "@mui/icons-material/ArrowBackIosRounded";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Section, { SpineItem } from "epubjs/types/section";

type Font = { lineHeight: string; size: string };

function setFont(rendition: Rendition, font: Font) {
  rendition.themes.override("line-height", font.lineHeight ?? "2em");
  rendition.themes.override("font-size", font.size ?? "100%");
}

function getLabelForSection(book: Book, section: SpineItem | null) {
  if (section && section.href) {
    const navItem = book.navigation.get(section.href);
    return navItem ? navItem.label : "";
  }
  return false;
}

export type BookProgress = {
  page: number;
  total: number;
  label: string;
};

export type EpubRenditionProps = HTMLAttributes<HTMLDivElement> & {
  book: Book;
  location: BookLocation;
  onLocationChange: (location: BookLocation) => void;
  onTextClicked: (event: MouseEvent, text: string) => void;
  onBookProgress: (progress: BookProgress) => void;
  font: Font;
};

export default function EpubRendition({
  book,
  location,
  onLocationChange,
  onTextClicked,
  onBookProgress,
  font: hotFont,
  ...divProps
}: EpubRenditionProps) {
  const nodeRef = useRef<HTMLDivElement>(null);
  const [rendition, setRendition] = useState<Rendition>();
  const font = useDebounce<Font>(hotFont, 500);
  const [next, setNext] = useState<string | boolean>(false);
  const [prev, setPrev] = useState<string | boolean>(false);

  const handleButtonClick = (direction: string) => () => {
    direction === "next" && rendition?.next();
    direction === "prev" && rendition?.prev();
  };

  useEffect(() => {
    if (!nodeRef.current) {
      return;
    }

    console.log("Create Rendition");
    // @ts-ignore
    const rendition = book.renderTo(nodeRef.current, {
      flow: "scrolled-doc",
      width: "100vw",
      fullsize: true,
    });

    rendition.hooks.content.register((contents: any) => {
      const body: HTMLElement = contents.content;
      const document: Document = contents.document;

      if (!document.getElementById("before-padding")) {
        const before = document.createElement("div");
        before.id = "before-padding";
        before.style.height = "64px";
        body.prepend(before);
      }

      if (!document.getElementById("after-padding")) {
        const after = document.createElement("div");
        after.id = "after-padding";
        after.style.height = "128px";
        body.append(after);
      }
    });

    const handleKeyPress = ({ key }: { key?: string }) => {
      key === "ArrowRight" && rendition?.next();
      key === "ArrowLeft" && rendition?.prev();
    };

    const handleClick = (event: MouseEvent) => {
      const element = event.target as HTMLElement;
      if (onTextClicked && element?.innerText) {
        const text = selectTextInDocument(element.ownerDocument, "sentence");
        if (text) {
          onTextClicked(event, text);
          event.preventDefault();
        }
      }
    };

    rendition.start();
    rendition.started.then(() => setRendition(rendition));

    rendition.on("relocated", (loc: Location) => {
      const location: BookLocation = loc?.start?.cfi ?? null;
      onLocationChange(location);
      const displayed = loc?.start?.displayed;
      if (displayed) {
        const current = book.navigation.get(loc?.start?.href);
        onBookProgress({
          page: displayed?.page,
          total: displayed?.total,
          label: current?.label,
        });
      }
    });

    rendition.on("keyup", handleKeyPress);

    rendition.on("rendered", (section: Section, view: any) => {
      setNext(getLabelForSection(book, section.next()));
      setPrev(getLabelForSection(book, section.prev()));

      if (!("document" in view)) {
        throw new Error(
          "View does not contain document, click listener cannot be registered"
        );
      }
      view.document?.addEventListener("click", handleClick);
    });

    return () => {
      console.log("Destroy rendition");
      setRendition(undefined);
      try {
        rendition.destroy();
      } catch (e) {
        // When unmounting because of React Strict the first time fails
        if (
          !(e instanceof TypeError) ||
          e.message !==
            "Cannot read properties of undefined (reading 'destroy')"
        ) {
          throw e;
        }
      }
    };
  }, [book, onLocationChange, onBookProgress, onTextClicked]);

  useEffect(() => {
    const handleKeyPress = ({ key }: { key?: string }) => {
      key === "ArrowRight" && rendition?.next();
      key === "ArrowLeft" && rendition?.prev();
    };

    document.addEventListener("keyup", handleKeyPress, false);

    return () => {
      document.removeEventListener("keyup", handleKeyPress, false);
    };
  }, [rendition]);

  useEffect(() => {
    if (rendition) {
      console.log("Set font", font);
      setFont(rendition, font);
      rendition.clear();
      rendition.display(rendition.location?.start?.cfi ?? undefined);
    }
  }, [rendition, font]);

  useEffect(() => {
    if (rendition && rendition?.location?.start?.cfi !== location) {
      console.log("Set location", location);
      rendition.display(location ?? undefined);
    }
  }, [rendition, location]);

  return (
    <Box sx={{ position: "relative" }}>
      <div ref={nodeRef} {...divProps} />
      <Box
        sx={{ position: "absolute", top: 0, left: 0, right: 0 }}
        display="flex"
        justifyContent="center"
        p={2}
      >
        <Button
          color="inherit"
          disabled={prev === false}
          variant="contained"
          onClick={handleButtonClick("prev")}
          startIcon={<ArrowBackIosRoundedIcon />}
        >
          {prev}
        </Button>
      </Box>
      <Box
        sx={{ position: "absolute", bottom: 0, left: 0, right: 0 }}
        display="flex"
        justifyContent="center"
        p={2}
      >
        <Button
          color="inherit"
          disabled={next === false}
          variant="contained"
          onClick={handleButtonClick("next")}
          endIcon={<ArrowForwardIosRoundedIcon />}
        >
          {next}
        </Button>
      </Box>
    </Box>
  );
}
