import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slider from "@mui/material/Slider";

export type Font = { lineHeight: string; size: string };

export default function FontConfigurationDialog({
  value,
  onChange,
  open,
  onClose,
}: {
  value: Font;
  onChange: (font: Font) => void;
  open: boolean;
  onClose: () => void;
}) {
  const handleFontSizeChange = (e: any, newValue: number | number[]) => {
    if (typeof newValue !== "number") {
      throw new Error("Expected newValue to be of type number");
    }
    const size = newValue + "%";
    if (size !== value.size) {
      onChange({
        ...value,
        size,
      });
    }
  };

  const handleLineHeightChange = (e: any, newValue: number | number[]) => {
    if (typeof newValue !== "number") {
      throw new Error("Expected newValue to be of type number");
    }
    const lineHeight = newValue / 10 + "em";
    if (lineHeight !== value.lineHeight) {
      onChange({
        ...value,
        lineHeight,
      });
    }
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
      >
        <DialogTitle id="form-dialog-title">Font settings</DialogTitle>
        <DialogContent>
          <DialogContentText>Font size</DialogContentText>
          <Slider
            value={parseInt(value.size)}
            track={false}
            min={25}
            max={250}
            onChange={handleFontSizeChange}
            valueLabelDisplay="auto"
            valueLabelFormat={(value) => value + "%"}
          />
          <DialogContentText>Line height</DialogContentText>
          <Slider
            value={parseFloat(value.lineHeight) * 10}
            track={false}
            min={5}
            step={1}
            max={30}
            onChange={handleLineHeightChange}
            valueLabelDisplay="auto"
            valueLabelFormat={(value) => value / 10 + "em"}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
