import Epub from "epubjs";
import BookRepository from "./services/BookRepository";

export default async function addUploadedBookHandler(
  repository: BookRepository,
  file: File
) {
  if (file.type !== "application/epub+zip" && !/\.epub$/i.test(file.name)) {
    throw new Error("Unsupported type");
  }

  const buffer = await readToArrayBuffer(file);
  const metadata = await extractMetadata(buffer);

  await repository.add(
    {
      name: file.name,
      file: buffer,
    },
    {
      name: file.name,
      title: metadata?.title,
      author: metadata?.author,
      language: metadata?.language,
    },
    metadata.cover
  );
}

async function extractMetadata(book: ArrayBuffer) {
  // @ts-ignore
  const epubBook = Epub(book, {});
  await epubBook.opened;
  const coverUrl = await epubBook.coverUrl();
  const cover = coverUrl ? await urlToBlob(coverUrl) : null;
  const metadata = epubBook?.packaging?.metadata;

  return {
    title: metadata?.title,
    author: metadata?.creator,
    language: metadata?.language,
    cover,
  };
}

/**
 * @param {File|Blob} file
 * @return {Promise<ArrayBuffer>}
 */
async function readToArrayBuffer(file: File | Blob): Promise<ArrayBuffer> {
  const reader = new FileReader();
  return new Promise((resolve, reject) => {
    reader.onload = () => {
      const result = reader.result;
      if (result instanceof ArrayBuffer) {
        return resolve(result);
      }
      return reject(new Error("Expected reader result to be a ArrayBuffer"));
    };
    reader.onerror = reject;
    reader.readAsArrayBuffer(file);
  });
}

async function urlToBlob(url: string) {
  const response = await fetch(url);
  return response.blob();
}
