import { AppBar, Box, CircularProgress, Fade, Toolbar } from "@mui/material";

export default function FullPageProgress() {
  return (
    <>
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          position: "absolute",
        }}
      >
        <Fade in style={{ transitionDelay: "800ms" }} unmountOnExit>
          <CircularProgress />
        </Fade>
      </Box>
      <AppBar sx={{ top: "auto", bottom: 0 }} position="fixed">
        <Toolbar />
      </AppBar>
    </>
  );
}
