import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useSnackbar } from "notistack";
import BookRepository, {
  BookLocation,
  BookMetadata,
  BookName,
  migrate,
} from "../../services/BookRepository";
import addUploadedBookHandler from "../../addUploadedBookHandler";
import FullPageProgress from "../FullPageProgress";

type BookCollectionContext = {
  books: Array<BookMetadata>;
  repository: BookRepository;
  setBooks: Function;
};

const context = createContext<BookCollectionContext | null>(null);

const requestPersistedStorage = async (enqueueSnackbar: Function) => {
  const warnStorageNotGuaranteed = () =>
    enqueueSnackbar("Browser could not guarantee that books will be saved", {
      variant: "error",
    });

  if (!navigator.storage || !navigator.storage.persist) {
    warnStorageNotGuaranteed();
    return;
  }

  try {
    if (!(await navigator.storage.persist())) {
      warnStorageNotGuaranteed();
    }
  } catch (error) {
    console.error(error);
    warnStorageNotGuaranteed();
  }
};

function useBookCollectionContext() {
  const bookCollectionContext = useContext(context);
  if (bookCollectionContext === null) {
    throw new Error(
      "Book collection context not provided use the BookCollectionProvider"
    );
  }

  return bookCollectionContext;
}

export function useBookCollection() {
  const { books, repository, setBooks } = useBookCollectionContext();
  const { enqueueSnackbar } = useSnackbar();

  return useMemo(
    () => ({
      books,
      async addBookFromFile(file: File) {
        await addUploadedBookHandler(repository, file);
        const books = await repository.findAllMetadata();
        await requestPersistedStorage(enqueueSnackbar);

        setBooks(books);
      },
    }),
    [books, repository, setBooks, enqueueSnackbar]
  );
}

export function useBook(name: BookName) {
  const { books, repository, setBooks } = useBookCollectionContext();

  return useMemo((): {
    metadata: BookMetadata;
    file: Promise<ArrayBuffer>;
    updateLocation(location: BookLocation): Promise<void>;
    removeBook(): Promise<void>;
  } => {
    const metadata = books.find((book) => book.name === name);
    if (!metadata) {
      throw new Error("Book not found");
    }

    return {
      metadata,
      file: repository.loadFile(name),
      async updateLocation(location: BookLocation) {
        metadata.location = location;
        await repository.updateLocation(name, location);
      },
      async removeBook() {
        await repository.remove(name);
        const books = await repository.findAllMetadata();

        setBooks(books);
      },
    };
  }, [name, books, setBooks, repository]);
}

export function BookCollectionProvider({
  storage,
  children,
}: {
  storage: LocalForage;
  children: ReactNode;
}) {
  const [books, setBooks] = useState<Array<BookMetadata> | null>(null);
  const { enqueueSnackbar } = useSnackbar();

  const repository = useMemo(() => new BookRepository(storage), [storage]);

  useEffect(() => {
    migrate(repository)
      .then(() => repository.findAllMetadata())
      .then(setBooks)
      .catch((err) => {
        enqueueSnackbar("Could not read storage", { variant: "error" });
        console.error("Could not get books from storage", err);
      });
  }, [repository, enqueueSnackbar]);

  if (!books) {
    return <FullPageProgress />;
  }

  return (
    <context.Provider value={{ books, setBooks, repository }}>
      {children}
    </context.Provider>
  );
}
