export async function migrate(repository: BookRepository) {
  const keys = await repository.findAllNames();
  await Promise.all(keys.map(migrateCover(repository.storage)));
}

const migrateCover = (storage: LocalForage) => async (name: string) => {
  const coverKey = name + "_cover";
  const cover = await storage.getItem(coverKey);
  if (typeof cover === "string") {
    const blob = await (await fetch(cover)).blob();
    await storage.setItem(coverKey, blob);
  }
};

export type BookName = string;
export type BookLocation = string | null;

export type Book = {
  name: BookName;
  file: ArrayBuffer;
};

export type BookMetadata = {
  name: BookName;
  cover?: string | null;
  title: string;
  author: string | null;
  language: string | null;
  location?: BookLocation;
};

export default class BookRepository {
  storage;
  _coverUrls;

  constructor(storage: LocalForage) {
    this.storage = storage;
    this._coverUrls = new Map();
  }

  async add(
    book: Book,
    metadata: BookMetadata,
    cover: Blob | null
  ): Promise<void> {
    const { name, file } = book;
    await Promise.all([
      this.storage.setItem(name + "_file", file),
      this.storage.setItem(name + "_cover", cover),
      this.storage.setItem(name + "_metadata", metadata),
      this.storage.setItem(name + "_location", undefined),
    ]);
  }

  async remove(name: BookName): Promise<void> {
    await Promise.all([
      this.storage.removeItem(name + "_file"),
      this.storage.removeItem(name + "_cover"),
      this.storage.removeItem(name + "_metadata"),
      this.storage.removeItem(name + "_location"),
    ]);
    const coverUrl = this._coverUrls.get(name);
    if (coverUrl) {
      URL.revokeObjectURL(coverUrl);
      this._coverUrls.delete(name);
    }
  }

  async findAllNames(): Promise<BookName[]> {
    const keys = await this.storage.keys();
    const fileKey = /_file$/;
    return keys
      .filter((key) => fileKey.test(key))
      .map((key) => key.replace(fileKey, ""));
  }

  async findAllMetadata(): Promise<BookMetadata[]> {
    const names = await this.findAllNames();
    const books = await Promise.all(
      names.map(async (name) => this.loadMetadata(name))
    );
    books.sort((a, b) => a.title.localeCompare(b.title));
    return books;
  }

  async loadMetadata(name: BookName): Promise<BookMetadata> {
    const cover = await this.loadCover(name);
    const metadata = await this.storage.getItem<BookMetadata>(
      name + "_metadata"
    );
    const location = await this.storage.getItem<BookLocation>(
      name + "_location"
    );

    if (!metadata) {
      throw new Error("Could not find metadata");
    }

    return {
      name,
      cover,
      location,
      author: metadata.author,
      title: metadata.title,
      language: metadata.language,
    };
  }

  async loadFile(name: BookName): Promise<ArrayBuffer> {
    const file = await this.storage.getItem<ArrayBuffer>(name + "_file");
    if (!file) {
      throw new Error("Could not find file for book " + name);
    }

    return file;
  }

  async updateLocation(name: BookName, location: BookLocation): Promise<void> {
    await this.storage.setItem(name + "_location", location);
  }

  async loadCover(name: BookName): Promise<string> {
    if (!this._coverUrls.has(name)) {
      const cover = await this.storage.getItem(name + "_cover");

      this._coverUrls.set(
        name,
        cover instanceof Blob ? URL.createObjectURL(cover) : cover
      );
    }
    return this._coverUrls.get(name);
  }
}
