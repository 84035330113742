import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { useBookCollection } from "../../providers/bookCollection";
import { BookMetadata } from "../../../services/BookRepository";
import { Box } from "@mui/material";

function Book({ book, onClick }: { book: BookMetadata; onClick: Function }) {
  return (
    <Paper
      elevation={3}
      sx={{
        position: "relative",
        height: 0,
        paddingTop: "160%", // aspect ratio of 1.6:1
        cursor: "pointer",
      }}
      onClick={() => {
        onClick();
      }}
    >
      {book.cover ? (
        <Paper
          elevation={0}
          sx={{
            position: "absolute",
            top: 0,
            width: "100%",
            height: "100%",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
          style={{ backgroundImage: "url(" + book.cover + ")" }}
        />
      ) : (
        <Box
          sx={{
            position: "absolute",
            top: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography align="center" color="textPrimary" variant="subtitle1">
            {book.title || book.name.replace(/\.epub$/i, "")}
          </Typography>
          {book.author && (
            <Typography align="center" color="textSecondary">
              By {book.author}
            </Typography>
          )}
        </Box>
      )}
    </Paper>
  );
}

export default function BookShelf({ onBookClick }: { onBookClick: Function }) {
  const { books } = useBookCollection();
  books.sort((a, b) => a.title.localeCompare(b.title));

  return (
    <Grid
      container
      spacing={2}
      sx={{
        padding: 2,
      }}
    >
      {books.map((book) => (
        <Grid key={book.name} item xs={6} sm={4} md={4} lg={3} xl={2}>
          <Book book={book} onClick={() => onBookClick(book)} />
        </Grid>
      ))}
    </Grid>
  );
}
