import {
  useEffect,
  useState,
  MouseEvent as ReactMouseEvent,
  useCallback,
} from "react";
import {
  AppBar,
  Box,
  Drawer,
  Toolbar,
  IconButton,
  useMediaQuery,
  Theme,
  Hidden,
} from "@mui/material";
import DropdownMenu from "../../DropdownMenu";
import Epub, { Book } from "epubjs";
import FullPageProgress from "../../FullPageProgress";
import TableOfContentsList from "./TableOfContentsList";
import TocIcon from "@mui/icons-material/Toc";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DeleteIcon from "@mui/icons-material/Delete";
import { useSetting } from "../../providers/settings";
import { useBook } from "../../providers/bookCollection";
import FontConfigurationDialog, { Font } from "./FontConfigurationDialog";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import fscreen from "fscreen";
import ConfirmDialog from "../../ConfirmDialog";
import SpeechSynth from "../../../services/SpeechSynth";

import beigeBackground from "./backgrounds/beige.jpg";
import EpubRendition, { BookProgress } from "./EpubRendition";
import TextFormatIcon from "@mui/icons-material/TextFormat";

function useEpubBook(file: Promise<ArrayBuffer>) {
  const [state, setState] = useState<Book>();

  useEffect(() => {
    let cancelled = false;
    console.log("Loading book");
    file
      .then(async (arrayBuffer) => {
        // @ts-ignore
        const epubBook = new Epub(arrayBuffer, {});
        await epubBook.opened;
        if (!cancelled) {
          setState(epubBook);
        }
      })
      .catch((e) => console.error(e));

    return () => {
      cancelled = true;
    };
  }, [file]);

  return state;
}

export default function ReadingPage({
  speechSynth,
  bookName,
  onClose,
}: {
  speechSynth: SpeechSynth;
  bookName: string;
  onClose: () => void;
}) {
  const { metadata, file, updateLocation, removeBook } = useBook(bookName);
  const epubBook = useEpubBook(file);
  const [progress, setProgress] = useState<BookProgress | null>(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [fontConfigDialogOpen, setFontConfigDialogOpen] = useState(false);
  const [fullscreen, setFullscreen] = useState(
    fscreen.fullscreenElement !== null
  );
  const smallScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );
  const { setting: font, setSetting: setFont } = useSetting<Font>("font");

  useEffect(() => {
    const handler = () => {
      setFullscreen(fscreen.fullscreenElement !== null);
      // Dispatch a resize event so that the rendition is also updated
      window.dispatchEvent(new Event("resize"));
    };
    fscreen.addEventListener("fullscreenchange", handler);

    return () => {
      fscreen.removeEventListener("fullscreenchange", handler);
      fscreen.fullscreenElement && fscreen.exitFullscreen();
    };
  }, []);

  const location = metadata?.location ?? null;

  const toggleDrawer =
    (open: boolean) => (event: KeyboardEvent | ReactMouseEvent<any>) => {
      if (
        event instanceof KeyboardEvent &&
        event.type === "keydown" &&
        (event.key === "Tab" || event.key === "Shift")
      ) {
        return;
      }
      setDrawerOpen(open);
    };

  const handleTextClicked = useCallback(
    (event: MouseEvent, word: string) => {
      const element = event.target as HTMLElement;

      const lang = (element.lang || metadata?.language) ?? undefined;
      speechSynth.speak(word, { lang });
    },
    [speechSynth, metadata]
  );

  const handleBookRemove = (confirmed: boolean) => {
    setDeleteDialogOpen(false);
    if (confirmed) {
      onClose();
      removeBook();
    }
  };

  const handleTocClick = (tocItem: { href: string }) => {
    updateLocation(tocItem.href);
    setDrawerOpen(false);
  };

  const handleFullscreenClick = () => {
    if (fullscreen) {
      fscreen.exitFullscreen();
    } else {
      fscreen.requestFullscreen(document.documentElement);
    }
  };

  if (!metadata || !epubBook) {
    return <FullPageProgress />;
  }

  return (
    <>
      <Box
        sx={{
          overflowY: "hidden",
          backgroundImage: "url('" + beigeBackground + "')",
          minHeight: "100%",
        }}
      >
        <EpubRendition
          book={epubBook}
          location={location}
          onLocationChange={updateLocation}
          onTextClicked={handleTextClicked}
          onBookProgress={setProgress}
          font={font}
        />
        <Toolbar />
      </Box>

      <AppBar sx={{ top: "auto", bottom: 0 }} position="fixed">
        <Toolbar>
          <Hidden smDown>
            <IconButton
              edge="start"
              color="inherit"
              sx={{
                marginRight: 2,
              }}
              onClick={() => {
                onClose();
              }}
            >
              <ArrowBackIcon />
            </IconButton>
          </Hidden>
          <Box
            sx={{
              flexGrow: 1,
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {progress && progress.label}
          </Box>
          <Box>{progress && `(${progress.page}/${progress.total})`}</Box>
          {!smallScreen && (
            <IconButton
              color="inherit"
              onClick={() => setFontConfigDialogOpen(true)}
            >
              <TextFormatIcon />
            </IconButton>
          )}
          {fscreen.fullscreenEnabled && (
            <IconButton color="inherit" onClick={handleFullscreenClick}>
              {fullscreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
            </IconButton>
          )}
          {epubBook?.navigation?.toc.length > 0 && (
            <>
              <IconButton
                color="inherit"
                aria-label="menu"
                onClick={toggleDrawer(true)}
              >
                <TocIcon />
              </IconButton>
              <Drawer
                anchor="right"
                open={drawerOpen}
                onClose={toggleDrawer(false)}
              >
                <Box sx={{ maxWidth: "75vw" }}>
                  <TableOfContentsList
                    book={epubBook}
                    onClick={handleTocClick}
                  />
                </Box>
              </Drawer>
            </>
          )}
          <DropdownMenu
            menuItems={[
              {
                text: "Remove Book",
                icon: <DeleteIcon />,
                onClick: () => setDeleteDialogOpen(true),
              },
              {
                text: "Font settings",
                icon: <TextFormatIcon />,
                onClick() {
                  setFontConfigDialogOpen(true);
                },
              },
            ]}
          />
        </Toolbar>
      </AppBar>
      <FontConfigurationDialog
        open={fontConfigDialogOpen}
        value={font}
        onChange={setFont}
        onClose={() => setFontConfigDialogOpen(false)}
      />
      <ConfirmDialog
        open={deleteDialogOpen}
        onClose={handleBookRemove}
        title={"Remove Book"}
        description={"Are you sure you want to remove " + metadata.title + "?"}
      />
    </>
  );
}
