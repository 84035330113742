import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

export const serviceWorkerStatusChanged = "serviceWorkerStatusChanged";
export type ServiceWorkerStatusChangedEvent = {
  status: string;
  skipWaiting?: Function;
};

function dispatchServiceWorkerStatusChanged(
  status: string,
  registration: ServiceWorkerRegistration
) {
  if (status === "install") {
    window.dispatchEvent(
      new CustomEvent<ServiceWorkerStatusChangedEvent>(
        serviceWorkerStatusChanged,
        {
          detail: { status },
        }
      )
    );
    return;
  }

  const waitingServiceWorker = registration.waiting;
  if (status === "update" && waitingServiceWorker) {
    waitingServiceWorker.addEventListener("statechange", () => {
      if (waitingServiceWorker.state === "activated") {
        window.location.reload();
      }
    });

    window.dispatchEvent(
      new CustomEvent<ServiceWorkerStatusChangedEvent>(
        serviceWorkerStatusChanged,
        {
          detail: {
            status,
            skipWaiting: () => {
              waitingServiceWorker.postMessage({ type: "SKIP_WAITING" });
            },
          },
        }
      )
    );
  }
}

export default function registerServiceWorker({
  updateInterval,
}: {
  updateInterval: number;
}) {
  serviceWorkerRegistration.register({
    onSuccess(registration) {
      dispatchServiceWorkerStatusChanged("install", registration);
    },
    onUpdate(registration) {
      dispatchServiceWorkerStatusChanged("update", registration);
    },
  });
  if ("serviceWorker" in navigator) {
    navigator.serviceWorker.ready.then((registration) => {
      if (registration.waiting) {
        dispatchServiceWorkerStatusChanged("update", registration);
      }
      setInterval(() => {
        registration.update();
      }, updateInterval);
    });
  }
}
