import { ChangeEvent } from "react";
import BookShelf from "./BookShelf";
import DropdownMenu from "../../DropdownMenu";
import AddIcon from "@mui/icons-material/Add";
import FileInput from "./FileInput";
import { useSnackbar } from "notistack";
import { useBookCollection } from "../../providers/bookCollection";
import { BookMetadata } from "../../../services/BookRepository";
import { AppBar, Box, Fab, Toolbar } from "@mui/material";

export default function OverviewPage({
  onBookSelected,
}: {
  onBookSelected: (book: BookMetadata) => void;
}) {
  const { enqueueSnackbar } = useSnackbar();
  const { addBookFromFile } = useBookCollection();

  const handleFileSelected = async (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (!files || files.length === 0) {
      return;
    }

    for (let i = 0; i < files.length; i++) {
      const file = files.item(i);
      if (!file) {
        continue;
      }
      if (file.type !== "application/epub+zip" && !/\.epub$/i.test(file.name)) {
        enqueueSnackbar("Unsupported file type " + file.name, {
          variant: "error",
        });
        console.log("Unsupported file type " + file.name, file);
        continue;
      }

      await addBookFromFile(file);
    }
  };

  return (
    <>
      <BookShelf onBookClick={onBookSelected} />
      <AppBar position="fixed" sx={{ top: "auto", bottom: 0 }}>
        <Toolbar>
          <FileInput
            onChange={handleFileSelected}
            multiple
            accept="application/epub+zip,.epub"
          >
            <Fab
              color="secondary"
              aria-label="add"
              sx={{
                position: "absolute",
                zIndex: 1,
                top: -30,
                left: 0,
                right: 0,
                margin: "0 auto",
              }}
            >
              <AddIcon />
            </Fab>
          </FileInput>
          <Box sx={{ flexGrow: 1 }} />
          <DropdownMenu menuItems={[]} />
        </Toolbar>
      </AppBar>
      <Toolbar />
    </>
  );
}
