import { InputHTMLAttributes, ReactNode, useRef } from "react";

export default function FileInput({
  children,
  ...props
}: InputHTMLAttributes<HTMLInputElement> & {
  children: ReactNode;
}) {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleClick = () => {
    fileInputRef.current?.click();
  };

  return (
    <div onClick={handleClick}>
      <input
        {...props}
        type="file"
        ref={fileInputRef}
        onClick={() => {
          if (fileInputRef.current) {
            fileInputRef.current.value = "";
          }
        }}
        style={{ position: "absolute", top: "-9999px" }}
      />
      {children}
    </div>
  );
}
