import { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import OverviewPage from "./pages/overview/OverviewPage";
import ReadingPage from "./pages/reading/ReadingPage";
import { useSnackbar } from "notistack";
import useInstallPrompt from "./providers/useInstallPrompt";
import SpeechSynth from "../services/SpeechSynth";
import {
  serviceWorkerStatusChanged,
  ServiceWorkerStatusChangedEvent,
} from "../registerServiceWorker";

type NavigateParams =
  | { page: "book"; book: string }
  | { page: "overview" }
  | { page: string; [index: string]: string };

function App({ speechSynth }: { speechSynth: SpeechSynth }) {
  useInstallPrompt();
  const [page, setPage] = useState<NavigateParams>(window.history.state || {});
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const listener = (event: PopStateEvent) => {
      setPage(event.state || {});
    };
    window.addEventListener("popstate", listener);
    return () => {
      window.removeEventListener("popstate", listener);
    };
  }, []);

  useEffect(() => {
    const listener = ((event: CustomEvent<ServiceWorkerStatusChangedEvent>) => {
      switch (event.detail.status) {
        case "install":
          enqueueSnackbar("Caching complete. Ready to use offline.");
          break;
        case "update":
          event.preventDefault();
          enqueueSnackbar("New version available. Reload to use new version.", {
            autoHideDuration: 10000,
            action: (key) => (
              <Button
                color="inherit"
                variant="outlined"
                onClick={() => {
                  if (event.detail.skipWaiting) {
                    event.detail.skipWaiting();
                  }
                }}
              >
                Reload
              </Button>
            ),
          });
          break;
        default:
          console.error(
            "Unknown serviceWorkerStatusChanged",
            event.detail.status
          );
      }
    }) as EventListener;

    window.addEventListener(serviceWorkerStatusChanged, listener);
    return () => {
      window.removeEventListener(serviceWorkerStatusChanged, listener);
    };
  }, [enqueueSnackbar]);

  const navigate = (page: NavigateParams) => {
    if (page.page === "overview") {
      window.history.back();
      return;
    }

    window.history.pushState(page, page.page);
    setPage(page);
  };

  if (page.page === "book" && page.book) {
    return (
      <ReadingPage
        bookName={page.book}
        onClose={() => navigate({ page: "overview" })}
        speechSynth={speechSynth}
      />
    );
  }

  return (
    <OverviewPage
      onBookSelected={(book) => navigate({ page: "book", book: book.name })}
    />
  );
}

export default App;
