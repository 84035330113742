export default class SpeechSynth {
  async speak(
    text: string,
    config: {
      voice?: SpeechSynthesisVoice;
      lang?: string;
      pitch?: number;
      rate?: number;
      volume?: number;
    }
  ): Promise<void> {
    if (!text) {
      return;
    }

    const utterance = new SpeechSynthesisUtterance(text);
    utterance.lang = config.lang ?? "nl";
    utterance.pitch = config.pitch ?? 1;
    utterance.rate = config.rate ?? 0.8;
    utterance.volume = config.volume ?? 1;
    if (config.voice) {
      utterance.voice = config.voice;
    }

    const promise = new Promise<void>((resolve, reject) => {
      utterance.onend = () => resolve();
      utterance.onerror = reject;
    });

    if (window.speechSynthesis.pending || window.speechSynthesis.speaking) {
      window.speechSynthesis.cancel();
    }

    window.speechSynthesis.speak(utterance);

    return promise;
  }
}
