import {
  useContext,
  createContext,
  useState,
  useEffect,
  useCallback,
  ReactNode,
} from "react";
import { useSnackbar } from "notistack";
import FullPageProgress from "../FullPageProgress";

type Settings = Record<string, unknown>;
type SetSettings = (settings: Settings) => void;

const settingsContext = createContext<Settings>({});
const setSettingsContext = createContext<SetSettings>(function () {
  throw new Error("Settings not yet initialized");
});

export function useSetting<T>(name: string): {
  setting: T;
  setSetting: (arg: T) => void;
} {
  const setSettings = useContext<SetSettings>(setSettingsContext);
  const settings = useContext<Settings>(settingsContext);
  const setSetting = useCallback(
    (newValue: T) => {
      if (settings[name] !== newValue) {
        setSettings({
          ...settings,
          [name]: newValue,
        });
      }
    },
    [name, settings, setSettings]
  );

  return { setting: settings[name] as T, setSetting };
}

export function SettingsProvider({
  storage,
  defaultSettings,
  children,
}: {
  storage: LocalForage;
  defaultSettings: Settings;
  children: ReactNode;
}) {
  const [settings, setSettings] = useState<Settings>({});
  const { enqueueSnackbar } = useSnackbar();

  const changeSettings = useCallback(
    async (settings: Settings) => {
      setSettings(settings);
      try {
        await storage.setItem("settings", settings);
      } catch (err) {
        enqueueSnackbar("Could not persist settings", { variant: "warning" });
        console.error("Could not persist settings", err);
      }
    },
    [storage, enqueueSnackbar]
  );

  useEffect(() => {
    storage
      .getItem<Settings>("settings")
      .then((settings) => {
        setSettings(settings || defaultSettings);
      })
      .catch((err) => {
        enqueueSnackbar("Could not read settings", { variant: "warning" });
        console.error("Could not get settings from storage", err);
        setSettings(defaultSettings);
      });
  }, [storage, enqueueSnackbar, defaultSettings]);

  if (!settings) {
    return <FullPageProgress />;
  }

  return (
    <setSettingsContext.Provider value={changeSettings}>
      <settingsContext.Provider value={settings}>
        {children}
      </settingsContext.Provider>
    </setSettingsContext.Provider>
  );
}
