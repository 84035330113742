import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

export type ConfirmDialogProps = {
  open: boolean;
  onClose: (state: boolean) => void;
  title: string;
  description: string;
  ok?: string;
  cancel?: string;
};

export default function ConfirmDialog({
  open,
  onClose,
  title,
  description,
  ok = "Ok",
  cancel = "Cancel",
}: ConfirmDialogProps) {
  return (
    <Dialog
      open={open}
      onClose={() => onClose(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {description}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose(false)} color="primary">
          {cancel}
        </Button>
        <Button onClick={() => onClose(true)} color="primary" autoFocus>
          {ok}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
