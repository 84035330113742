type Granularity =
  | "character"
  | "word"
  | "sentence"
  | "line"
  | "paragraph"
  | "lineboundary"
  | "sentenceboundary"
  | "paragraphboundary"
  | "documentboundary";

export function selectTextInDocument(
  document: Document,
  granularity: Granularity
) {
  const window = document.defaultView;
  if (!window) {
    return null;
  }

  const selection = window.getSelection();
  if (!selection || !selection.isCollapsed) {
    return null;
  }

  selection.modify("move", "forward", "character");
  selection.modify("move", "backward", granularity);
  selection.modify("extend", "forward", granularity);

  return selection.toString();
}
