import { useEffect, useRef } from "react";
import Button from "@mui/material/Button";
import { SnackbarKey, useSnackbar } from "notistack";

export default function useInstallPrompt() {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const installed = useRef<boolean>();

  useEffect(() => {
    const listener = function (e: BeforeInstallPromptEvent) {
      e.preventDefault();
      // Check if the app is already installed
      if (
        installed.current ||
        navigator.standalone ||
        window.matchMedia("(display-mode: standalone)").matches
      ) {
        installed.current = true;
        return;
      }

      const handleClick = (key: SnackbarKey) => () => {
        closeSnackbar(key);
        installed.current = true;
        e.prompt();
      };
      enqueueSnackbar("This webapp can be installed as a standalone app.", {
        autoHideDuration: 10000,
        action: (key: SnackbarKey) => (
          <Button color="inherit" variant="outlined" onClick={handleClick(key)}>
            Install
          </Button>
        ),
        variant: "info",
      });
    };
    window.addEventListener("beforeinstallprompt", listener);

    return () => {
      window.removeEventListener("beforeinstallprompt", listener);
    };
  }, [enqueueSnackbar, closeSnackbar]);

  return installed.current;
}
