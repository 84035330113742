import { useState, MouseEvent, ReactNode } from "react";
import IconButton from "@mui/material/IconButton";
import MoreIcon from "@mui/icons-material/MoreVert";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { ListItemIcon, ListItemText } from "@mui/material";

export type DropdownMenuItem = {
  onClick: () => void;
  text: string;
  icon?: ReactNode;
};

export default function DropdownMenu({
  menuItems,
}: {
  menuItems: Array<DropdownMenuItem>;
}) {
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const open = Boolean(anchorEl);

  if (!menuItems || menuItems.length === 0) {
    return null;
  }

  const handleMenu = (event: MouseEvent) => {
    setAnchorEl(event.currentTarget as Element);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleMenuItemClick = (next: DropdownMenuItem["onClick"]) => () => {
    handleClose();
    next();
  };

  return (
    <>
      <IconButton
        aria-label="display more actions"
        edge="end"
        color="inherit"
        onClick={handleMenu}
      >
        <MoreIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={open}
        onClose={handleClose}
      >
        {menuItems &&
          menuItems.map(({ onClick, text, icon }, index) => {
            return (
              <MenuItem key={index} onClick={handleMenuItemClick(onClick)}>
                {icon && <ListItemIcon>{icon}</ListItemIcon>}
                <ListItemText>{text}</ListItemText>
              </MenuItem>
            );
          })}
      </Menu>
    </>
  );
}
